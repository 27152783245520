
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$FertilityDashboardApp-primary: mat.define-palette(mat.$indigo-palette);
$FertilityDashboardApp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$FertilityDashboardApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$FertilityDashboardApp-theme: mat.define-light-theme((
  color: (
    primary: $FertilityDashboardApp-primary,
    accent: $FertilityDashboardApp-accent,
    warn: $FertilityDashboardApp-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($FertilityDashboardApp-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'Merck-Regular';
    src: url('./assets/fonts/Merck-Regular.ttf');
}

body, p, b {
    margin: 0;
}

body *{
    box-sizing: border-box;
}

.merck-font-title{
    font-family: 'Merck-Regular';
    color: #503291;
    font-size: 32px;
    font-weight: 400;
    line-height: 44px;
    word-wrap: break-word;
}

p {
    color: #503291;
    margin: 0;
}

th, td, p:not(.merck-font-title, .merck-font), input{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}

.mat-mdc-icon-button{
    padding: 0 !important;
    display: flex !important;
    --mdc-icon-button-icon-size: 14px !important;
    --mat-mdc-button-persistent-ripple-color:transparent !important;
    --mat-mdc-button-ripple-color: transparent !important;
    --mat-mdc-button-persistent-ripple-color: transparent !important;

    .mat-mdc-button-base {
        padding: 0 !important;      
    }
}

.mat-datepicker-content .mat-calendar {
    width: 229px !important;
    height: 307px !important;
}

.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #503291;
}

.mat-calendar-arrow{
    fill: #503291 !important;
} 

.mat-calendar-table-header th, 
.mat-calendar-body-label, 
.mat-calendar-body-cell-content, 
.mat-date-range-input-separator,
.mat-calendar-next-button, 
.mat-calendar-previous-button{
    color: #503291 !important;
}

.mat-mdc-button {
    --mat-mdc-button-persistent-ripple-color: transparent !important;
    --mat-mdc-button-ripple-color: transparent !important;
}

.mat-date-range-input-wrapper{
    width: 4vw !important;
    min-width: 80px !important;
}

.mat-calendar-body-selected {
    background-color: #E3D9F9 !important;
}

.mat-calendar-body-cell-container{
    --mat-datepicker-calendar-date-in-range-state-background-color: #F6F2FF;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    border-color: transparent !important;
}

.mat-date-range-input-separator-hidden{
    opacity: 1 !important;
    margin: 0 8px 0 4px !important;
}

.mat-divider{
    border-top-color: #503291 !important;
}

::-webkit-scrollbar{
    width: 3px;
    background-color: #EDECF6;
}
  
::-webkit-scrollbar-thumb{
    background: #BBB5E0;
    width: 1px;
    border-radius: 8px;
}

.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #503291;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.navigation-wrapper {
    .mat-mdc-icon-button {
        --mdc-icon-button-icon-size: 32px !important;
    }

    .mat-mdc-icon-button.mat-mdc-button-base, .mat-mdc-icon-button {
        padding: 0;
    }    
}

.stepper *:not(mat-icon) {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.stepper {
    .mat-step .mat-step-header {
        border-radius: 10px;

        &:hover, &:focus, &:focus-visible {
            background-color: #D5DCEB;
        }
    }

    .mat-step-icon-selected, mat-icon:not(.mat-step-icon-state-error mat-icon), .mat-step-icon{
        background-color: #503291;
    }

    .mat-step-icon-state-error{
        background-color: #E9ECF3 !important;
    }

    .mat-step-label-selected .mat-step-text-label {
        font-weight: 700;
    }

    .mat-step-text-label:not(.mat-step-label-error .mat-step-text-label) {
        color: #503291;
    }

    .mat-mdc-radio-button {
        color: #503291 !important;
        --mdc-radio-selected-focus-icon-color: #503291;
        --mdc-radio-selected-hover-icon-color: #503291;
        --mdc-radio-selected-icon-color: #503291;
        --mdc-radio-selected-pressed-icon-color: #503291;
        --mat-radio-checked-ripple-color: #503291;

        .mdc-form-field {
            color: #503291 !important;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.mat-calendar-table-header-divider::after {
    background: rgba(80, 50, 145, 0.6) !important;;
}

app-information-input {
    height: 100vh;
}

.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: #E9ECF3;
    --mdc-snackbar-supporting-text-color: #503291;
    --mat-snack-bar-button-color: #503291;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    font-family:  Verdana, Geneva, Tahoma, sans-serif;
}

.fd-snack-bar *{
    align-items: center;
    display: flex;
    justify-content: center;
}

.mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    border-radius: 10px !important;
}